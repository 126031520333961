<template>
    <div class="index" id="index">
        <div class="banner">
            <el-carousel height="546px" :interval="3000" indicator-position="outside">
                <el-carousel-item >
                    <div>
                        <img src="../assets/PC/home_img_banner.png" alt="">
                        <!-- <div class="text" :style="textLeft">
                            <h2>依蓝云专注智慧交通场景应用<br>解决方案</h2>
                        </div> -->
                    </div>
                </el-carousel-item>
                <el-carousel-item >
                    <div>
                        <img src="../assets/banner/banner1.png" alt="">
                        <!-- <div class="text" :style="textLeft">
                            <h2>95128电召智慧交通软硬件<br><span>解决方案</span></h2>
                        </div> -->
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div>
                        <img src="../assets/banner/banner2.png" alt="">
                        <!-- <div class="text" :style="textLeft">
                            <h2>定制客运软硬件<br><span>解决方案</span></h2>
                        </div> -->
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div>
                        <img src="../assets/banner/banner3.png" alt="">
                        <!-- <div class="text" :style="textLeft">
                            <h2>软件架构设计开发<br><span>解决方案</span></h2>
                        </div> -->
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="bannerH5">
            
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item>
                    <div class="swipeCon">
                        <img src="../assets/H5/home_img_banner.png" alt="">
                        <!-- <h2>依蓝云专注智慧交通场景应用解决方案</h2> -->
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="swipeCon">
                        <img src="../assets/banner/banner1.1.png" alt="">
                        <!-- <h2>95128电召智慧交通软硬件<br><span>解决方案</span></h2> -->
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="swipeCon">
                        <img src="../assets/banner/banner2.2.png" alt="">
                        <!-- <h2>定制客运软硬件<br><span>解决方案</span></h2> -->
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="swipeCon">
                        <img src="../assets/banner/banner3.3.png" alt="">
                        <!-- <h2>软件架构设计开发<br><span>解决方案</span></h2> -->
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- <h2 style="    position: fixed;top:50%;z-index: 11;">{{scrollIndex}}<br>{{herfIndex}}<br>{{cardT}}<br>{{cardH}}<br>{{cardDispaly}}</h2> -->
        <!-- 简介 -->
        <div class="company">
            <h6 class="h6"> 依蓝云科技有限公司（简称：“依蓝云”）专注基于场景化应用技术（SAT）解决方案的供应商。</h6>
            <h6 class="h6" style="text-indent:1em"> 自2006年成立以来，坚守用优秀创意为社会创造价值这一使命，坚持原创设计、自主研发、创新营销的经营理念，团队以真诚、上进、担当、协同为价值观，致力于将带有社会责任感的创新产品和服务带给每个人、每个组织，促进各行业发展。</h6>
            <h6 class="h6" style="text-indent:1em"> 依蓝云软件、硬件、SaaS产品可涵盖95128、110、120、119、122指挥中心升级数字化、场景化，传统客运升级定制客运数字化、场景化，传统数据中心升级可视化、虚拟化、私有化，提供解决方案和整体服务。为广泛的用户提供品牌策划、定制开发和公有云计算服务。</h6>
            <h6 class="h6" style="text-indent:1em">   公司总部位于中国北京，在北京、青岛、内蒙古设有销售、技术和运营服务中心，产品已应用于全国多个地级城市。</h6>
            <!-- <h6>我们的使命是“用优秀创意，为社会创造价值”</h6> -->
            <ul class="main">
                <li >
                    <img src="../assets/PC/company1.png" alt="">
                    <span>公司简介</span>
                    <div @click="company(0)" class="liHide" >
                        <img @click="company(0,'mobile')"  src="../assets/PC/ic_door.png" alt="">
                    </div>
                </li>
                <li>
                    <img src="../assets/PC/company2.png" alt="">
                    <span>企业文化</span>
                    <div @click="company(4)" class="liHide" >
                        <img @click="company(4,'mobile')"  src="../assets/PC/ic_door.png" alt="">
                    </div>
                </li>
                <li>
                    <img src="../assets/PC/company3.png" alt="">
                    <span>办公地点</span>
                    <div @click="company(5)" class="liHide" >
                        <img @click="company(5,'mobile')"  src="../assets/PC/ic_door.png" alt="">
                    </div>
                </li>
            </ul>
        </div>

        <div class="face">
            <div class="card">
                <div class="card-l" style="width:150px;display: inline-block;">
                    <h5>面向用户</h5>
                    <pre>智慧生活   便捷出行</pre>
                </div>
                <ul>
                    <li @click="work(1,0)">
                        <img src="../assets/PC/index_li1.png" alt="">
                        <span>生活出行</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="work(0,1)">
                        <img src="../assets/PC/index_li2.png" alt="">
                        <span>定制客运</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="work(0,2)">
                        <img src="../assets/PC/index_li3.png" alt="">
                        <span>儿童教育</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card">
                <div class="card-l" style="width:150px;display: inline-block;">
                    <h5>面向政企</h5>
                    <pre>数字化 场景化 虚拟化</pre>
                </div>
                <ul>
                    <li @click="work(1,3)">
                        <img src="../assets/PC/img_1.png" alt="">
                        <span>数字交通</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="work(1,4)">
                        <img src="../assets/PC/img_2.png" alt="">
                        <span>虚拟化云计算</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="work(1,6)">
                        <img src="../assets/PC/img_3.png" alt="">
                        <span>需求定制</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                </ul>
            </div>
            
            <!-- <div class="card card2">
                <div class="card-l" style="width:150px;display: inline-block;">
                    <h5>面向政企</h5>
                    <pre>数字化 场景化 虚拟化</pre>
                </div>
                <ul> 
            
                   <li @click="work(1,3)">
                        <img src="../assets/PC/index_li4.png" alt="">
                        <span style="left: -7px">数字交通</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="work(1,4)">
                        <img src="../assets/PC/index_li5.png" alt="">
                        <span style="left: -7px">虚拟化云计算</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="work(1,6)">
                        <img src="../assets/PC/index_li7.png" alt="">
                        <span style="left: -7px">需求定制</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    
                </ul>
            </div> -->
            <div class="card card3">
                <div class="card-l" style="width:150px;display: inline-block;">
                    <h5>面向社会</h5>
                    <pre>全球战疫   助力公益</pre>
                </div>
                <ul>
                    <li @click="navClick('/enterprise',0)">
                        <img src="../assets/PC/index_li8.png" alt="">
                        <span>全球抗疫  互译共读</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                    <li @click="navClick('/enterprise',1)">
                        <img src="../assets/PC/index_li9.png" alt="">
                        <span>县域城市  助力高考</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card card4">
                <div class="card-l" style="width:150px;display: inline-block;">
                    <h5>面向人才</h5>
                    <pre>链合人才  激发活力</pre>
                </div>
                <ul>
                    <li @click="navClick('/staff',0)">
                        <img src="../assets/PC/index_li10.png" alt="">
                        <span>链合优质人才  激发企业与个人活力共同成长</span>
                        <img class="img_r" src="../assets/PC/ic_arrow.png" alt="">
                        <div class="liHide">
                        </div>
                    </li>
                </ul>
            </div>

            <div class="cardH5">
                <ul>
                    <li id="cardH5_1">
                        <h5>面向用户</h5>
                        <p>智慧生活  便捷出行</p>
                        <div class="h5Li" :style="cardDispaly==0?'display: block;':''">
                            <div class="h5li_con"  @click="navClick('/work',0)">
                                <span>- 生活出行 -</span>
                                <span>- 定制客运 -</span>
                                <span>- 儿童教育 -</span>
                            </div>
                        </div>
                    </li>
                    <li id="cardH5_2">
                        <h5>面向政企</h5>
                        <p>数字交通 虚拟化云计算 需求定制</p>
                        <div class="h5Li" :style="cardDispaly==1?'display: block;':''"  @click="navClick('/work',1)">
                            <div class="h5li_con">
                                <span>- 数字交通 -</span>
                                <span>- 虚拟云计算 -</span>
                                <span>- 需求定制 -</span>
                            </div>
                        </div>
                    </li>
                    <li id="cardH5_3">
                        <h5>面向社会</h5>
                        <p>全球战疫  助力公益</p>
                        <div class="h5Li" :style="cardDispaly==2?'display: block;':''"   @click="navClick('/enterprise')">
                            <div class="h5li_con">
                                <span>- 全球抗疫  互译共读 -</span>
                                <span>- 县域城市  助力高考 -</span>
                            </div>
                        </div>
                    </li>
                    <li id="cardH5_4">
                        <h5>面向人才</h5>
                        <p>链合人才  激发活力</p>
                        <div class="h5Li" :style="cardDispaly==3?'display: block;':''"  @click="navClick('/staff',0)">
                            <div class="h5li_con">
                                <span>- 链合人才  激发活力 -</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            textLeft:'left:0px',
            scrollIndex:'',
            herfIndex:"",//可视高度
            cardT:"",
            cardH:"", //自身高度
            cardLi:[],
            cardDispalytype:'none',
            cardDispaly:'none',
            cardDispalyNumber:0,
            time:0,
        }
    },
     watch: {
      cardDispalytype(newV, oldV) {
        //   console.log(newV)
          let this_=this;
          let time;
          this_.cardDispalyNumber==0
          clearInterval(this_.time)
          if(newV!='none'){
                this_.time=setInterval(function(){
                    this_.cardDispalyNumber = this_.cardDispalyNumber + 1
                    if(this_.cardDispalyNumber>3){
                        this_.cardDispaly=newV
                        // console.log(this_.cardDispaly)
                    }
                },1000)
                        // 
          }else{
              clearInterval(this_.time)
          }
      },
    },
    mounted () {
        if(this.$look()){
            this.cardLi=[
                document.querySelector('#cardH5_1').offsetTop,
                document.querySelector('#cardH5_2').offsetTop,
                document.querySelector('#cardH5_3').offsetTop,
                document.querySelector('#cardH5_4').offsetTop,
            ]
            this.herfIndex = document.body.clientWidth 
            this.cardH=document.getElementById("cardH5_1").offsetHeight
        }
        // 检测界面宽度设置left
        if(document.body.clientWidth>1270){
            this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
        }else{
            this.textLeft='left:20px' 
        }
        window.onresize = () => {
            return (() => {
                if(document.body.clientWidth>1270){
                    this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
                }else{
                    this.textLeft='left:20px'
                }
            })();
        };
        window.addEventListener('scroll', this.menuIndex)
    },
    methods: {
        navClick(path,index){
            this.$router.push({
                path:path,
                query:{
                    herfIndex:index
                }
            });
        },
        // 跳转定制
        service(type){
            this.$router.push({
                path:'/work',
                query:{
                    herfIndex:2
                }
            })
        },
        menuIndex(){
            let timer ;
            let this_=this;
            this.cardDispaly='none';
            // clearTimeout(timer)
            //到顶高度 -可视高度            到顶高度 -可视高度+自身高度
            this.scrollIndex = document.documentElement.scrollTop || document.body.scrollTop;
            if(this.scrollIndex>(this.cardLi[0]-this.herfIndex) && this.scrollIndex<(this.cardLi[0]-this.herfIndex+this.cardH)){
                // console.log('cardDispaly')
                this.cardDispalytype=0
            }else if(this.scrollIndex>(this.cardLi[1]-this.herfIndex) && this.scrollIndex<(this.cardLi[1]-this.herfIndex+this.cardH)){
                this.cardDispalytype=1
            }else if(this.scrollIndex>(this.cardLi[2]-this.herfIndex) && this.scrollIndex<(this.cardLi[2]-this.herfIndex+this.cardH)){
                this.cardDispalytype=2
            }else if(this.scrollIndex>(this.cardLi[3]-this.herfIndex) && this.scrollIndex<(this.cardLi[3]-this.herfIndex+this.cardH)){
                this.cardDispalytype=3
            }else{
                this.cardDispalytype='none'
            }
        },
        work(index,id,alertType){
            if(alertType){
                this.$router.push({
                    path:'/work',
                    query:{
                        herfIndex:index,
                        id:id,
                        alertType:alertType
                    }
                });    
            }else{
                this.$router.push({
                    path:'/work',
                    query:{
                        herfIndex:index,
                        id:id
                    }
                });
            }
        },
        // 跳转简介
        company(type,hide){
            if(this.$look()){
                if(hide=='mobile'){
                    this.$router.push({
                        path:'/introduce',
                        query:{
                            herfIndex:type
                        }
                    })    
                }
            }else{
                this.$router.push({
                    path:'/introduce',
                    query:{
                        herfIndex:type
                    }
                })
            }
        }
    },
    beforeDestroy() { // 在组件生命周期结束的时候销毁。
        window.removeEventListener('scroll', this.menuIndex)
        clearInterval(this.time)
    },
}
</script>
<style lang="less">
    .index{
        min-width: 1100px;
        .bannerH5{
            display: none;
        }
        .banner{
            position: relative;
            width: 100%;
            height: 546px;
            .el-carousel__indicators--outside{
                position: absolute;
                bottom:10px
            }
            .el-carousel__indicator--horizontal .el-carousel__button {
                width: 10px;
                height: 10px;
                background: rgba(255,255,255,0.2);
                border: 1px solid #ffffff;
                border-radius: 50%;
                opacity: 0.5;
            }  
            .el-carousel__indicator--horizontal.is-active .el-carousel__button{
                width: 10px;
                height: 10px;
                background: #ffffff;
                border-radius: 50%;
                opacity: 1;
            }
            img{
                width: 100%;
                height: 644px;
                // float: left;
            }
                .text{
                    position: absolute;
                    left: 12%;
                    top: 50%;
                    transform: translateY(-50%);
                    h2{
                        font-size: 64px;
                        width: 900px;
                        color: #fff;
                        font-weight: normal;
                        span{
                            display: inline-block;
                            padding-bottom: 1px;
                            border-bottom: 3px solid #fff;
                        }
                    }
                }
        }

        .company{
            margin-top: 90px;
            h6{
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 14px;
            }
            .h6{
                width: 1270px;
                margin: 0 auto;
            }
            text-align: center;
            .main{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 76px;
                li{
                    cursor: pointer;
                    width: 32%;
                    position: relative;
                    img{
                        width: 100%;
                        height: 240px;
                    }
                    span{
                        display: inline-block;
                        width: 100%;
                        margin-top: 24px;
                        font-size: 16px;
                    }
                    .liHide{
                        display: none;
                        position: absolute;
                        width: 100%;
                        height: 240px;
                        line-height: 240px;
                        left: 0;
                        top: 0;
                        background-color: rgba(0, 156, 255, 0.8);
                        img{
                            width: 48px;
                            height: 56px;
                        }
                    }
                }
                li:hover .liHide{
                    display: block;
                }
                li:hover span{
                    color: #009cff;
                }
            }
            .main::-webkit-scrollbar {display:none}
        }

        .face{
            margin-bottom: 120px;
            .cardH5{
                display: none;
            }
            .card{
                position: relative;
                box-sizing: border-box;
                height: 320px;
                max-width: 1270px;
                margin:60px auto 0;
                border-left:8px solid  transparent;
                padding-left: 42px;
                h5{
                    font-size: 36px;
                    margin-bottom: 23px;
                    font-weight: 400;
                }
                p{
                    font-size: 16px;
                }
                pre{
                    font: 16px/1.5 arial, "microsoft yahei";
                }
                ul{
                    position: absolute;
                    right: 0;
                    top: 0;
                    li{
                        cursor: pointer;
                        position: relative;
                        float: left;
                        width: 313px;
                        height: 320px;
                        // transform: skewX(-10deg);
                        img{
                            position: absolute;
                            right: 1px;
                            width: 300px;
                            height: 320px;
                        }
                        span{
                            position: absolute;
                            bottom: 30px;
                            left: 50px;
                            font-size: 24px;
                            color: #fff;
                            z-index: 1;
                            border-bottom: 2px solid transparent;
                        }
                        .img_r{
                            display: none;
                            position: absolute;
                            right: 62px;
                            bottom: 34px;
                            width: 24px!important;
                            height: 24px;
                            z-index: 1;
                        }
                        .liHide{
                            display: none;
                            // transform: skewX(-7deg);
                            background: url(../assets/PC/index_blueli1.png) no-repeat ;
                            background-size: 100% 100%;
                            position: absolute;
                            width: 302px;
                            height: 320px;
                            top: 0;
                            right: 0;
                            // background-color: rgba(0, 156, 255, 0.8);
                        }
                    }
                    li:nth-child(1){
                        right: -128px;
                    }
                    li:nth-child(2){
                        right: -64px;
                    }
                    li:hover .img_r{
                        display: block;
                    }
                    li:hover .liHide{
                        display: block;
                    }
                    li:hover span{
                        border-bottom: 2px solid #fff;
                    }
                }
            }
            .card:hover{
                border-left:8px solid  #009cff!important;
            }
            .card.card2{
                border-left:8px solid transparent;
                ul{
                    li{
                        width: 260px;
                        img{
                            width: 305px;
                        }
                        .liHide{
                            width: 308px;
                            right: 0;
                            background: url(../assets/PC/index_blueli4.png) no-repeat ;
                            background-size: 100% 100%;
                            img{
                                width: 24px;
                            }
                        }
                    }
                    li:nth-child(1){
                        right: 9px!important;
                    }
                    li:nth-child(2){
                        right: 25px!important;
                    }
                    li:nth-child(3){
                        right: 45px!important;
                    }
                }
            }
            .card.card3{
                border-left:8px solid transparent;
                ul{
                    li{
                        width: 450px;
                        img{
                            width: 430px;
                        }
                        .liHide{
                            width: 432px;
                            background: url(../assets/PC/index_blueli9.png) no-repeat ;
                            background-size: 100% 100%;
                            img{
                                width: 24px;
                            }
                        }
                    }
                    li:nth-child(1){
                        right: -65px!important;
                    }
                    li:nth-child(2){
                        right: -0px!important;
                    }
                }
            }
            .card.card4{
                border-left:8px solid transparent;
                ul{
                    li{
                        width: 900px;
                        img{
                            width: 830px;
                        }
                        .liHide{
                            width: 832px;
                            background: url(../assets/PC/index_blueli10.png) no-repeat ;
                            background-size: 100% 100%;
                            img{
                                width: 24px;
                            }
                        }
                    }
                    li:nth-child(1){
                        right: -0px!important;
                        span{
                            left: 90px;
                        }
                    }
                }
            }
        }
    }
        // 手机端样式
        #app.H5Class {
            .index{
                min-width: 7.5rem;
            }
            .banner{
                display: none;
            }
            .introduce{
                padding-top:1rem ;
            }
            .bannerH5{
                overflow: hidden;
                width: 100%;
                display: block;
                height: 8.8rem;
                background:url(../assets/H5/home_img_banner.png) no-repeat;
                background-size: 100% 100%;
                .van-swipe{
                    height: 8.8rem;
                    .swipeCon{
                        position: relative;
                        img{
                            height: 8.8rem;
                        }
                    }
                }
                h2{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 2rem;
                    color: #fff;
                    font-size: 0.5rem;
                    // text-align: center;
                    width: 6.2rem;
                    span{
                        display: inline-block;
                        border-bottom: 0.04rem solid #fff;
                    }
                }
            }
            .company{
                margin-top:0.8rem;
                h6{
                    width:7rem;
                    margin: 0 auto;
                    font-size: 14px;
                        line-height: 1.6;
                }
                .main{
                    overflow-x: scroll;
                    -webkit-overflow-scrolling:touch;
                    display: -webkit-box;
                    white-space: nowrap;
                    margin-top: 0.8rem;
                    padding-left: 0.35rem;
                    li{
                        width: 4rem;
                        margin-right: 0.3rem;
                        img{
                            width: 4rem;
                            height: 2.4rem;
                        }
                        span{
                            display: block;
                            font-size: 0.24rem;
                            margin-top: 0.3rem;
                            padding-bottom: 0.1rem;
                        }
                        .liHide{
                            width: 4rem;
                            height: 2.4rem;
                            line-height: 2.4rem;
                            img{
                                width: 0.5rem;
                                height: 0.6rem;
                            }
                        }
                    }
                    li:hover  span{
                        font-weight: normal;
                        color: #009cff;
                    }
                }
            }
            .face{
                margin-top: 1rem;
                margin-bottom: 1.2rem;
                .card{
                    display: none;
                }
                .cardH5{
                    display: block;
                    width: 6.8rem;
                    margin: 0 auto;
                    li{
                        position: relative;
                        width: 100%;
                        height: 4.8rem;
                        background:url(../assets/H5/cardH51.png) no-repeat;
                        background-size: 100% 100%;
                        margin-bottom: 0.3rem;
                        .h5Li{
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: #009cff;
                            width: 100%;
                            height: 4.8rem;
                            font-size: 0.3rem;
                            color: #fff;
                            .h5li_con{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                            }
                            span{
                                display: block;
                                width: 100%;
                                text-align: center; 
                                font-size: 0.28rem;
                                margin-bottom: 0.1rem;
                            }
                        }
                        h5{
                            position: absolute;
                            font-size: 0.34rem;
                            color: #fff;
                            bottom: 1.2rem;
                            left: 0.5rem;
                        }
                        p{  
                            position: absolute;
                            bottom: 0.6rem;
                            left: 0.5rem;
                            font-size: 0.24rem;
                            color: #fff;
                        }
                    }
                    li:nth-child(2){
                        background:url(../assets/H5/cardH52.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    li:nth-child(3){
                        background:url(../assets/H5/cardH53.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    li:nth-child(4){
                        background:url(../assets/H5/cardH54.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    // li:hover .h5Li{
                    //     display: block;
                    // }
                }
            }
        }
</style>